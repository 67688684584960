/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "primeicons/primeicons.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Prompt", "Times New Roman", Times, serif;
  @apply text-primary-text;
}

.p-dialog-content {
  margin: 0;
  font-family: "Prompt", "Times New Roman", Times, serif !important;
  @apply text-primary-text;
}

.p-highlight {
  background-color: #480b1b !important;
  border-radius: 0 !important;
  color: white;
}

.p-menu .menured .p-menuitem-text {
  @apply text-main-red;
}
